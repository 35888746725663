export const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  USER_MUTATION_START: "USER_MUTATION_START",
  USER_MUTATION_SUCCESS: "USER_MUTATION_SUCCESS",
  USER_MUTATION_FAILURE: "USER_MUTATION_FAILURE",
};
